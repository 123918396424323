import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Image from "next/image";

import SwipeableViews from "react-swipeable-views";

import StarIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import {
  carouselDot,
  carouselImage,
  dFlex,
  fixedBottom,
  fixedIcon,
  flexBetween,
} from "../themes/commonStyles";
import Link from "next/link";
// import moment from "moment";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import dayjs from 'dayjs';

const CarouselCard = ({ location }: any) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = location.images.length > 6 ? 6 : location.images.length; // so that we know how many dots

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1); // jumps when we click the next arrow
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1); // when we click the back arrow
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step); // handle swipe change
  };
  return (
    <Box
      className="carouselCard"
      sx={{
        flexGrow: 1,
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Box sx={fixedIcon}>
        <FavoriteBorderIcon style={{ color: "white" }} />
      </Box>

      <Link href={`/property/${location.id}`} passHref>
        {location.images.length && (
          <SwipeableViews
            axis={"x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {location.images.map((step: any, index: number) => {
              return (
                <Box key={index}>
                  <Image
                    priority={index === 0}
                    src={step}
                    alt={step}
                    width="327px"
                    height="310px"
                    layout={"responsive"}
                    objectFit="cover"
                    objectPosition="center"
                    loading={index !== 0 ? 'lazy' : 'eager'}
                    style={carouselImage}
                  />
                </Box>
              );
            })}
          </SwipeableViews>
        )}
      </Link>

      <Box sx={fixedBottom}>
        <MobileStepper
          sx={{ backgroundColor: "transparent" }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              sx={carouselDot}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <ChevronRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              sx={carouselDot}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <ChevronLeft />
            </Button>
          }
        />
      </Box>

      <Link href={`/property/${location.id}`} passHref>
        <Box sx={flexBetween}>
          <Box sx={{ mt: 2 }}>
            <Typography component="h3">
              <b>{location.title}</b>
            </Typography>
            <Typography component="h3">
              { location?.spaceType}
            </Typography>
            <Typography component="h4">
              {" "}
              {dayjs(location.checkin).format("DD MMM")} &#8208;{" "}
              {dayjs(location.checkout).format("DD MMM")}{" "}
            </Typography>
            <Typography component="h5">
              {" "}
              <b>{location.price}</b> night
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{  display: "flex",
              flexDirection: "row", alignItems: 'center'}}>
              {location.isNewListing ? (
                <React.Fragment>
                  <StarIcon fontSize='small' />
                  <Typography component="h5">New</Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <StarIcon fontSize='small' />
                  <Typography component="h5"> {location.avgRating}</Typography>
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default CarouselCard;
